<template>
  <div>
    <div class="overview">
      <div class="store-banner">
        <Image
          :imageHash="storeInfo.bannerImageHash"
          :lazy="false"
          v-if="storeInfo.bannerImageHash"
          :alt="storeInfo.bannerImageHash"
        ></Image>
        <img src="@/assets/other-images/banner.png" alt="banner" v-else />
      </div>
      <div class="personal-info">
        <div class="row">
          <!-- 大頭照 -->
          <div
            class="col-md-2 col-3 d-flex align-items-center"
            v-if="userLineLink"
          >
            <Image
              :url="profilePicture"
              v-if="profilePicture"
              :alt="profilePicture"
              style="width: 50px; height: 50px"
            ></Image>
            <img
              src="@/assets/other-images/nobody.png"
              alt=""
              style="width: 50px; height: 50px"
              v-else
            />
          </div>
          <!-- 資訊 -->
          <div class="col-md-10 col-9">
            <ul class="fw-bolder">
              <li class="mb-1">ID: {{ participantId }}</li>
              <li class="mb-1">名稱: {{ userInfo.name }}</li>
              <li class="mb-1">
                <span v-if="masterInfo.storeVipId">
                  VIP: {{ masterInfo.storeVip.name }}&ensp;
                </span>
                <span v-if="masterInfo.customerGroupId">
                  顧客群組: {{ masterInfo.customerGroup.name }}
                </span>
              </li>
              <li class="mb-1">
                未結單金額:
                <span v-if="!uncheckoutMoney.loading"
                  >{{ $methods.numberToFixed(uncheckoutMoney.data) }}元</span
                >
                <span class="text-danger" v-else>計算中...</span>
              </li>
              <li>購物金餘額: {{ masterInfo.wallet }}元</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="stint-tw-container-bg">
        <div class="tw-container stint-tw-container">
          <!-- 賣場資訊 -->
          <div class="mb-3 p-3">
            <!-- 企業資訊 -->
            <div
              class="row pointer mb-3 alert alert-primary px-0"
              @click="showModal('business')"
              v-if="business"
            >
              <div class="col-md-6 ms-0">
                <p class="fw-bolder">營業人名稱 : {{ business.companyName }}</p>
              </div>
              <div
                class="col-md-1 p-0 border mb-2 mb-md-0"
                style="width: 0"
              ></div>
              <div class="col-md-5">
                <p class="fw-bolder">
                  統一編號 : {{ business.businessAdminNumber }}
                </p>
              </div>
            </div>
            <!-- 賣場介紹/付款與出貨方式說明 -->
            <div class="row">
              <div
                class="col-md-6 pointer mb-4"
                @click="showModal('storeIntroduction')"
              >
                <p class="fw-bolder mb-2">賣場介紹:</p>
                <p class="text-break">{{ storeIntroduction }}</p>
              </div>
              <div class="col-md-1 p-0 border" style="width: 0"></div>
              <div
                class="col-md-5 pointer"
                @click="showModal('paymentDescription')"
              >
                <p class="fw-bolder mb-2">付款與出貨方式說明:</p>
                <p class="text-break">{{ paymentDescription }}</p>
              </div>
            </div>
          </div>
          <hr />
          <!-- 儲值回報 -->
          <MoneyReport :participantId="participantId"></MoneyReport>
          <hr />
          <!-- 購物金歷史紀錄 -->
          <WalletHistory :participantId="participantId"></WalletHistory>
          <hr />
          <!-- 收件資訊 -->
          <ReceivingInfo :participantId="participantId"></ReceivingInfo>
        </div>
      </div>
    </div>
  </div>
  <!-- 顯示的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="storeInfoModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">
            <span v-if="showWhat.status === 'storeIntroduction'">賣場介紹</span>
            <span v-else>付款與出貨方式說明</span>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-html="showWhat.value.replace(/(\r\n)|(\n)/g, '<br>')"></div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 企業資訊 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="businessModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">企業資訊</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="business">
            <p class="mb-2">
              <span class="fw-bolder">統一編號 : </span>
              {{ business.businessAdminNumber }}
            </p>
            <p class="mb-2">
              <span class="fw-bolder">營業人名稱 : </span>
              {{ business.companyName }}
            </p>
            <p class="mb-2">
              <span class="fw-bolder">公司負責人 : </span>
              {{ business.responsibleName }}
            </p>
            <p class="mb-2">
              <span class="fw-bolder">公司地址 : </span
              >{{ business.companyLocation }}
            </p>
            <p>
              <span class="fw-bolder mb-2">稅籍編號 : </span
              >{{ business.taxSerialNumber }}
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
import MoneyReport from '@/components/shopOverview/MoneyReport.vue'
import WalletHistory from '@/components/shopOverview/WalletHistory.vue'
import ReceivingInfo from '@/components/receipingInfo/ReceipingInfo.vue'
// api
import { parseTokenToGetTheParticipant } from '@/methods/API/parseTokenToGetTheParticipant.js'

export default {
  components: {MoneyReport, WalletHistory, ReceivingInfo},
  data() {
    return {
      // modal
      storeInfoModal: {},
      businessModal: {},
      // data
      storeId: 0,
      serverToken: '',
      storeInfo: {},
      participantId: 0,
      slaveId: 0,
      masterInfo: {},
      userInfo: {},
      userLineLink: null,
      userFbLink: null,
      profilePicture: '',
      business: null,
      uncheckoutMoney: {
        loading: true,
        data: 0
      },
      showWhat: {
        status: '',
        value: ''
      },
    }
  },
  created() {
    this.initialization()
    this.getParticipantId()
    this.getStoreInfo()
  },
  computed: {
    storeIntroduction() {
      const maxLength = 100;
      if (!this.storeInfo.introduction) return '無賣場介紹'
      else if (this.storeInfo.introduction.length > maxLength) return this.storeInfo.introduction.slice(0, maxLength) + '...'
      else return this.storeInfo.introduction
    },
    paymentDescription() {
      const maxLength = 100;
      if (!this.storeInfo.paymentDescription) return '無付款出貨說明'
      else if (this.storeInfo.paymentDescription.length > maxLength) return this.storeInfo.paymentDescription.slice(0, maxLength) + '...'
      else return this.storeInfo.paymentDescription
    }
  },
  mounted() {
    this.createModals(['storeInfoModal','businessModal'])
  },
  methods: {
    initialization() {
      this.storeId = this.$route.params.storeId
      this.serverToken = this.$methods.getCookie('serverToken')
    },
    showModal(status) {
      if (status === 'storeIntroduction' || status === 'paymentDescription') {
        this.showWhat.status = status
        if (status === 'storeIntroduction') this.showWhat.value = this.storeInfo.introduction ? this.storeInfo.introduction : '無賣場介紹'
        else this.showWhat.value = this.storeInfo.paymentDescription ? this.storeInfo.paymentDescription : '無付款出貨說明'
        this.storeInfoModal.show()
      } else if (status === 'business') {
        this.businessModal.show()
      }
    },
    // 取得 participantId 與 slaveId
    getParticipantId() {
      const result = parseTokenToGetTheParticipant(this.storeId, this.serverToken)
      result.then(res => {
        console.log(res)
        if (res.code === '200') {
          this.participantId = res.data.id
          this.slaveId = res.data.slaves.length > 0 ? res.data.slaves[0].id : 0
          this.getParticipantInfo()
        }
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得使用者資訊
    getParticipantInfo() {
      const vm = this
      const getParticipantInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      let participantIds = [this.participantId]
      if (this.slaveId) participantIds.push(this.slaveId)
      const data = [
        {
          type: 13,
          ids: participantIds,
          methods: '{getUser{getUserLineLink,getUserFacebookLink},getMerchOrders{getCheckOutQuantity},getStoreVip,getCustomerGroup}'
        }
      ]
      this.uncheckoutMoney.loading = true
      $.ajax({
        type: 'POST',
        async: true,
        url: getParticipantInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const participantInfo = res.data[0].objects[0]
            const slaveInfo = res.data[0].objects[1]
            vm.sortoutParticipantData(participantInfo, slaveInfo)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理 participant 資料
    sortoutParticipantData(participantInfo, slaveInfo) {
      this.masterInfo = participantInfo
      this.userInfo = participantInfo.user
      this.userLineLink = participantInfo.user.userLineLink
      this.userFbLink = participantInfo.user.userFacebookLink
      if (participantInfo.user.userLineLink && participantInfo.user.userLineLink.profilePicture) this.profilePicture = participantInfo.user.userLineLink.profilePicture
      else if (participantInfo.user.userFacebookLink && participantInfo.user.userFacebookLink.profilePicture) this.profilePicture = participantInfo.user.userFacebookLink.profilePicture
      // 計算未結單金額 (包含被合併帳號)
      this.uncheckoutMoney.data = 0
      setTimeout(() => {
        participantInfo.merchOrders.forEach(item => {
          this.uncheckoutMoney.data += this.$methods.numberToFixed((item.quantity - item.checkOutQuantity) * item.price)
        })
        if (slaveInfo) {
          slaveInfo.merchOrders.forEach(item => {
            this.uncheckoutMoney.data += this.$methods.numberToFixed((item.quantity - item.checkOutQuantity) * item.price)
          })
        }
        this.uncheckoutMoney.loading = false
      }, 1)
      this.$methods.switchLoading('hide')
    },
    // 取得賣場資訊
    getStoreInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{}'
        }
      ]
      this.prefer = {
        loading: true,
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = storeInfo
            if (storeInfo.businessAdminNumber || storeInfo.companyName) {
              vm.business = {
                businessAdminNumber : storeInfo.businessAdminNumber ? storeInfo.businessAdminNumber : '賣家未填寫',
                companyName : storeInfo.companyName ? storeInfo.companyName : '賣家未填寫',
                responsibleName : storeInfo.responsibleName ? storeInfo.responsibleName : '賣家未填寫',
                companyLocation : storeInfo.companyLocation ? storeInfo.companyLocation : '賣家未填寫',
                taxSerialNumber : storeInfo.taxSerialNumber ? storeInfo.taxSerialNumber : '賣家未填寫'
              }
            } 
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
  },
}
</script>